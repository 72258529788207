/* eslint-disable react/prop-types */
// import { PropTypes } from "prop-types";

import React, { useEffect, useReducer, useRef, useState } from "react";
import { css } from "@emotion/react";
import styled from "@emotion/styled";
import { ScreenHeight } from "~components";
import { useScroll, useWindowDimensions } from "~hooks";
import { MEDIA_QUERIES } from "~utils/helpers";

import { ReactComponent as Arrow } from "~assets/icons/arrow-light.svg";

import image from "~assets/images/home-headline-desktop.svg";
import imageXS from "~assets/images/home-headline-mobile.svg";

// =============================================================================
// globals

const DEFAULT_CURSOR_RADIUS = 100;
const CURSOR_RADIUS_SCALE = 0.075;
const FOG_FRAME_RATE = 60;
const IDLE_TIME_MAX = 4000;
const SMUDGE_PATH_MAX = 60;

// =============================================================================
// styled components

const FrostedGlass = styled.div`
  @keyframes scale-in {
    0% {
      opacity: 0;
      transform: scale(1.2);
    }
    100% {
      opacity: 1;
      transform: scale(1);
    }
  }

  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  overflow: hidden;

  animation: 5s ease-in-out scale-in forwards;
  opacity: 0;

  &:before {
    content: "";
    position: absolute;
    background: inherit;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    box-shadow: inset 0 0 75vw rgba(255, 255, 255, 0.7);
    filter: blur(14px);
  }
`;

// =============================================================================
// path reducer

const reducer = (state, action) => {
  switch (action.type) {
    case `idle_add`:
      return {
        ...state,
        idle: state.idle + action.idle_time
      };
    case `idle_clear`:
      return {
        ...state,
        idle: 0
      };
    case `path_push`:
      return {
        ...state,
        path: [...state.path, action.point]
      };
    case `path_clear`:
      return {
        ...state,
        path: []
      };
    default:
      return state;
  }
};

// =============================================================================
// core runnable parent

const FrostedCanvas = () => {
  // ---------------------------------------------------------------------------
  // context / ref / state

  const { scrollTop } = useScroll();
  const { isDesktop, windowSize } = useWindowDimensions();

  const cursorRef = useRef();
  const idleIntervalRef = useRef();
  const canvasRef = useRef();
  const containerRef = useRef();
  const svgRef = useRef();

  const [state, dispatch] = useReducer(reducer, {
    idle: 0,
    path: []
  });

  const [cursor, setCursor] = useState({
    x: 0,
    y: 0
  });
  const [cursorRadius, setCursorRadius] = useState(DEFAULT_CURSOR_RADIUS);
  const [canvasSize, setCanvasSize] = useState({
    width: 0,
    height: 0
  });
  const [dragging, setDragging] = useState(false);

  // ---------------------------------------------------------------------------
  // variables

  const primaryColor = `#7437D2`;
  // const secondaryColor = `#F6BDEE`;

  // ---------------------------------------------------------------------------
  // methods

  const getCanvas = () => canvasRef?.current;

  const getPosition = (e) => {
    const canvas = getCanvas();

    if (!canvas) {
      return null;
    }

    const { left, top } = canvas.getBoundingClientRect();

    return {
      x: e.clientX - left,
      y: e.clientY - top
    };
  };

  const resetCanvas = () => {
    const canvas = getCanvas();

    if (!canvas) {
      return;
    }

    const ctx = canvas.getContext(`2d`);

    ctx.save();

    ctx.beginPath();
    ctx.clearRect(0, 0, canvas.width, canvas.height);
    ctx.closePath();

    ctx.restore();
  };

  const idleStop = () => {
    resetCanvas();

    const polyline = document.querySelector(`#poly-smudge`);

    polyline.points.clear();

    dispatch({ type: `idle_clear` });
    dispatch({ type: `path_clear` });

    if (typeof idleIntervalRef?.current === `number`) {
      clearInterval(idleIntervalRef.current);

      idleIntervalRef.current = null;
    }
  };

  const idleStart = () => {
    if (idleIntervalRef?.current) {
      clearInterval(idleIntervalRef.current);

      idleIntervalRef.current = null;
    }

    const interval = setInterval(() => {
      requestAnimationFrame(() => {
        dispatch({ idle_time: 60, type: `idle_add` });
      });
    }, FOG_FRAME_RATE);

    idleIntervalRef.current = interval;
  };

  const dragStop = () => {
    idleStart();

    const canvas = getCanvas();

    if (!canvas) {
      return;
    }

    const ctx = canvas.getContext(`2d`);

    ctx.closePath();
    ctx.restore();
  };

  const dragStart = () => {
    const canvas = getCanvas();

    idleStop();

    if (!canvas) {
      return;
    }

    resetCanvas();

    const ctx = canvas.getContext(`2d`);

    ctx.save();
    ctx.moveTo(cursor.x, cursor.y);
    ctx.restore();

    dispatch({ point: cursor, type: `path_push` });
  };

  const dragMove = (e) => {
    const pos = getPosition(e);

    setCursor(pos);

    if (!dragging) {
      return;
    }

    dispatch({ point: pos, type: `path_push` });

    // if (state?.path?.length > FOG_START_DELAY && !idleIntervalRef?.current) {
    //   idleStart();
    // }

    if (state?.path?.length > SMUDGE_PATH_MAX) {
      setDragging(false);
    }
  };

  const onMouseDown = () => {
    idleStop();

    setDragging(true);
  };

  const onMouseUp = () => {
    setDragging(false);
  };

  const onMouseMove = (e) => {
    dragMove(e);
  };

  const drawPath = (percentComplete, path) => {
    const canvas = getCanvas();

    if (!canvas || !path?.[0]) {
      return;
    }

    const lineWidth = parseInt(cursorRadius - cursorRadius * percentComplete);
    const lineBlur = percentComplete * 12;

    // mask preparation //

    const polyline = document.querySelector(`#poly-smudge`);

    polyline.points.clear();
    polyline.setAttribute(`stroke-width`, lineWidth * 0.9);

    // canvas update start //
    const ctx = canvas.getContext(`2d`);

    ctx.save();

    ctx.clearRect(0, 0, canvas.width, canvas.height);

    ctx.beginPath();

    ctx.filter = `blur(${lineBlur}px)`;
    ctx.strokeStyle = `rgba(255, 255, 255, 0.05)`;
    ctx.lineCap = `round`;
    ctx.lineJoin = `round`;
    ctx.lineWidth = lineWidth;

    path.forEach((point) => {
      const { x, y } = point;

      // draw a white outline //
      ctx.lineTo(x, y);

      // push to the SVG mask //
      const svgPoint = svgRef.current.createSVGPoint();

      svgPoint.x = x;
      svgPoint.y = y;

      polyline.points.appendItem(svgPoint);
    });

    ctx.stroke();
    ctx.closePath();
    ctx.restore();
  };

  // ---------------------------------------------------------------------------
  // lifecycle

  //
  // reset on user scroll

  useEffect(() => {
    if (!isDesktop) {
      return;
    }

    setDragging(false);
    resetCanvas();
    idleStop();
  }, [scrollTop]);

  //
  // canvas dimensions; initial / resize

  useEffect(() => {
    if (!isDesktop || !containerRef?.current || windowSize?.width === 0) {
      return;
    }

    const { width, height } = containerRef.current.getBoundingClientRect();

    setCursorRadius(windowSize.width * CURSOR_RADIUS_SCALE);

    setCanvasSize({
      width,
      height
    });

    resetCanvas();
  }, [containerRef?.current, isDesktop, windowSize]);

  //
  // drag triggers

  useEffect(() => {
    if (!isDesktop) {
      return;
    }

    if (dragging) {
      dragStart();
    } else {
      dragStop();
    }
  }, [dragging]);

  //
  // path drawing listener

  useEffect(() => {
    if (!isDesktop) {
      return;
    }

    if (state?.idle > IDLE_TIME_MAX) {
      idleStop();

      return;
    }

    drawPath(state.idle / IDLE_TIME_MAX, state.path);
  }, [state?.path, state?.idle]);

  // ---------------------------------------------------------------------------
  // render

  return (
    <ScreenHeight
      _css={css`
        width: 100vw;
        position: relative;
        height: calc(100vh + 1px);
        overflow: hidden;
        background: ${primaryColor};
      `}
    >
      <div
        css={css`
          width: 100%;
          height: 100%;
          position: sticky;
          top: 0;
          right: 0;
          bottom: 0;
          left: 0;
          z-index: 40;
          padding: 2rem 0 3rem;
          display: flex;
          flex-direction: column;
          align-items: center;
          justify-content: space-between;
          pointer-events: none;
          text-align: center;
          text-transform: uppercase;
          color: var(--color-white);

          ${MEDIA_QUERIES.desktop} {
            display: none;
            padding: 6rem 0 3rem;
          }
        `}
      >
        <FrostedGlass>
          <div
            css={css`
              width: 100%;
              height: 100%;
              position: fixed;
              top: 0;
              right: 0;
              bottom: 0;
              left: 0;
              display: flex;
              flex-direction: column;
              align-items: center;
              justify-content: space-between;
              padding: 2rem 0 1rem;
            `}
          >
            <h3
              css={css`
                animation: var(--animation-appear-down);
                animation-duration: 3s;
              `}
              className="button"
            >
              Denada is defrosting...
            </h3>
          </div>
        </FrostedGlass>
      </div>

      {(!isDesktop && (
        <div
          css={css`
            position: static;
            width: 100vw;
          `}
        >
          <figure
            css={css`
              transform: translate3d(0, 0, 0);
              backface-visibility: hidden;

              position: absolute;

              height: 100vh;
              transition: height 999999s;
              top: 0;
              right: 0;
              left: 0;
              bottom: 0;
              background: ${primaryColor};

              display: flex;
              align-items: center;
              justify-content: center;

              ${MEDIA_QUERIES.desktop} {
                display: block;
              }
            `}
          >
            <img
              css={css`
                animation: 7s ease-in-out blur-in forwards;
                width: 83.33333%;
                position: relative;
                display: block;

                ${MEDIA_QUERIES.tablet} {
                  width: 50%;
                }

                ${MEDIA_QUERIES.desktop} {
                  width: 83.33333%;
                }
              `}
              src={imageXS}
              alt="Its summer. Let's chill."
            />
          </figure>

          <div
            css={css`
              animation: var(--animation-appear-up);
              animation-duration: 3s;
              animation-delay: 5s;

              transform: translate3d(0, 1rem, 0);
              opacity: 0;

              position: absolute;
              bottom: 0;
              width: 100vw;
              display: flex;
              justify-content: center;
              padding-bottom: 1rem;
            `}
          >
            <div
              css={css`
                animation: float var(--animation-float);

                padding-bottom: 4vw;

                ${MEDIA_QUERIES.desktop} {
                  padding-bottom: 0;
                }
              `}
            >
              <Arrow
                css={css`
                  width: 1rem;
                `}
                fill="var(--color-white)"
              />
            </div>
          </div>
        </div>
      )) || (
        <div
          css={css`
            width: 100vw;
            height: 100%;
            position: fixed;
            top: 0;
            right: 0;
            bottom: 0;
            left: 0;
          `}
        >
          <div
            css={css`
              transform: translate3d(${cursor.x}px, ${cursor.y}px, 0);
              transform-origin: 50% 50%;

              width: 8vw;
              height: 8vw;
              position: fixed;
              top: -4vw;
              left: -4vw;
              border-radius: 100%;
              background: black;
              z-index: 50;
              display: flex;
              align-items: center;
              justify-content: center;
              pointer-events: none;
            `}
          >
            <h4
              css={css`
                color: var(--color-white);
                white-space: no-wrap;
                font-size: 1.5vw;
              `}
              className="h2"
            >
              DRAG ME
            </h4>
          </div>

          {/* // */}

          <div
            ref={containerRef}
            css={css`
              display: none;

              ${MEDIA_QUERIES.desktop} {
                display: block;
              }

              transform: translate3d(0, 0, 0);
              backface-visibility: hidden;

              canvas {
                transform: translate3d(0, 0, 0);
                backface-visibility: hidden;
                z-index: 10;
                background: transparent;
              }

              width: 100vw;
              height: 100%;
              position: fixed;
              top: 0;
              right: 0;
              left: 0;
              background: ${primaryColor};
            `}
          >
            {/* blurred, static image */}
            <figure
              css={css`
                animation: var(--animation-appear-in);
                animation-duration: 5s;
                opacity: 0;

                width: 100%;
                height: 100%;
                position: absolute;
                top: 0;
                right: 0;
                bottom: 0;
                left: 0;
                display: flex;
                align-items: center;
                justify-content: center;
                z-index: 20;
                pointer-events: none;
                filter: blur(12px);
              `}
            >
              <img
                css={css`
                  width: 50%;
                  position: relative;
                `}
                src={image}
                alt="Its summer. Let's chill."
              />
            </figure>

            <div
              css={css`
                animation: var(--animation-appear-up);
                animation-duration: 3s;

                transform: translate3d(0, 1rem, 0);
                opacity: 0;

                position: absolute;
                bottom: 0;
                width: 100vw;
                display: flex;
                justify-content: center;
                padding-bottom: 1rem;
              `}
            >
              <div
                css={css`
                  animation: float var(--animation-float);

                  padding-bottom: 12.5vw;

                  ${MEDIA_QUERIES.desktop} {
                    padding-bottom: 0;
                  }
                `}
              >
                <Arrow
                  css={css`
                    width: 1rem;
                  `}
                  fill="var(--color-white)"
                />
              </div>
            </div>

            {/* cripsy, masked image */}
            <div
              css={css`
                width: 100%;
                height: 100%;
                position: absolute;
                top: 0;
                right: 0;
                bottom: 0;
                left: 0;
                display: flex;
                align-items: center;
                justify-content: center;
                z-index: 20;
                pointer-events: none;

                transform: translate3d(0, 0, 0);
                backface-visibility: hidden;
                opacity: ${1.5 - state.idle / IDLE_TIME_MAX};
                filter: blur(${(state.idle / IDLE_TIME_MAX) * 4}px);
              `}
            >
              <svg
                ref={svgRef}
                css={css`
                  position: relative;
                  display: block;
                  pointer-events: none;
                `}
                width={windowSize?.width || 0}
                height={windowSize?.height || 0}
                viewBox={`0 0 ${windowSize?.width || 0} ${
                  windowSize?.height || 0
                }`}
              >
                <mask
                  id="masking"
                  x="0"
                  y="0"
                  width={windowSize?.width || 0}
                  height={windowSize?.height || 0}
                >
                  <polyline
                    id="poly-smudge"
                    points=""
                    fill="transparent"
                    stroke="white"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth={cursorRadius}
                  />
                </mask>

                <g mask="url(#masking)">
                  <rect
                    x="0"
                    y="0"
                    width={windowSize?.width || 0}
                    height={windowSize?.height || 0}
                    fill={primaryColor}
                  />
                  <image
                    xlinkHref={image}
                    x={windowSize?.width / 4 || 0}
                    y="0"
                    width={windowSize?.width / 2 || 0}
                    height={windowSize?.height || 0}
                  />
                </g>

                <g transform={`translate(${cursor.x}, ${cursor.y})`}>
                  <circle
                    ref={cursorRef}
                    cx="0"
                    cy="0"
                    r={cursorRadius / 2}
                    opacity="0"
                  />
                  {/* stroke="000000"
                  fill="#000000" */}
                  {/* <text
                  className="button"
                  x="0"
                  y="0"
                  textAnchor="middle"
                  alignmentBaseline="middle"
                  fill="#ffffff"
                >
                  DRAG ME
                </text> */}
                </g>
              </svg>
            </div>

            <canvas
              ref={canvasRef}
              width={canvasSize?.width || 0}
              height={canvasSize?.height || 0}
              onMouseDown={onMouseDown}
              onMouseUp={onMouseUp}
              onMouseMove={onMouseMove}
            />
          </div>
        </div>
      )}
    </ScreenHeight>
  );
};

export default FrostedCanvas;
